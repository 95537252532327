import axios from 'axios'
import UserApi from './UserApi'
import CarApi from './CarApi'
import CatalogApi from './CatalogApi'
import OrderApi from './OrderApi'
import VouchersApi from "@/api/VouchersApi";
import CartApi from "@/api/CartApi";
import ReturnsApi from "@/api/ReturnsApi";
import PaymentApi from "@/api/PaymentApi";
import AddressApi from "@/api/PostalCodeApi";
import ShopApi from "@/api/ShopApi";
import DeliveryApi from "@/api/DeliveryApi";

axios.defaults.baseURL =  process.env.VUE_APP_BACKEND_HOST;

axios.interceptors.request.use(function(request) {

    if (request.baseURL === process.env.VUE_APP_BACKEND_HOST) {
        request.headers = Object.assign(request.headers, {
            'X-API-KEY': process.env.VUE_APP_MPA_API_KEY
        })
    }

    return request
}, function(error) {
  return Promise.reject(error)
})

export {
    UserApi,
    CarApi,
    CatalogApi,
    OrderApi,
    VouchersApi,
    CartApi,
    ReturnsApi,
    PaymentApi,
    AddressApi,
    ShopApi,
    DeliveryApi
}
